import { IInitiateRecoveryRequest } from "@finbackoffice/clientbff-client";
import { RequestError, formatAppNameText } from "@finbackoffice/fe-core";
import {
    userInfoValidationSchema,
    useRuntimeConfig,
    ClientBFFContext,
    useTranslation,
} from "@finbackoffice/site-core";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import { FC, useContext, useState, useCallback, useEffect } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useForm, Controller, FieldError } from "react-hook-form";
import { InferType, string } from "yup";
import Button from "components/base/button/Button";
import Img from "components/base/img/Img";
import InputField from "components/base/input-field/InputField";
import PhoneInput from "components/base/phone-input/PhoneInput";
import ToggleSwitch from "components/base/toggle-switch/ToggleSwitch";
import Translate from "components/base/translate/Translate";
import Input from "components/base/input-field/Input";
import styles from "./forgot-pass.module.sass";

type IForgotPassForm = InferType<ReturnType<typeof getForgotPassStep1ValidationSchema>>;

const getForgotPassStep1ValidationSchema = (activeTab: ActiveTabType) =>
    userInfoValidationSchema.pick([activeTab]).shape({
        code: string()
            .default("")
            .when("$isCodeSent", ([isCodeSent], schema: any) => {
                if (isCodeSent) {
                    return schema.required().min(6);
                }
                return;
            }),
    });

type ActiveTabType = "email" | "phone";

type IProps = {
    setTempId: (token: string) => void;
    setStep: (val: number) => void;
    setCode: (val: string) => void;
    id: string;
};

const Step1: FC<IProps> = ({ setTempId, setStep, id, setCode }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const emailEnabled = COMMON_SITE_CONFIGS.forgotPassMethods.email;
    const phoneEnabled = COMMON_SITE_CONFIGS.forgotPassMethods.phone;
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const client = useContext(ClientBFFContext);
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<ActiveTabType>(
        COMMON_SITE_CONFIGS.forgotPassMethods[COMMON_SITE_CONFIGS.login.defaultActiveTab]
            ? COMMON_SITE_CONFIGS.login.defaultActiveTab
            : emailEnabled
              ? "email"
              : "phone",
    );
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isResend, setIsResend] = useState(false);
    const [validUntil, setValidUntil] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const forgotPassConfigObj = {
        email: { name: "email", required: true },
        phone: { name: "phone", required: true },
    };

    const {
        register,
        control,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        getValues,
        formState: { isValid, isSubmitting, errors, isDirty, dirtyFields },
    } = useForm({
        mode: "onChange",
        shouldUnregister: true,
        context: {
            activeTab,
            isCodeSent,
            configObj: {
                ...forgotPassConfigObj,
                t,
            },
        },
        resolver: yupResolver(getForgotPassStep1ValidationSchema(activeTab)),
    });

    useEffect(() => {
        reset({ ...getValues() }, { keepDirty: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCodeSent]);

    const initiateRecovery = useCallback(
        async (data: IInitiateRecoveryRequest) => {
            if (data.login) {
                setLoading(true);
                try {
                    const response = await client.initiateRecovery(data);

                    setTempId(response.token.id);
                    setValidUntil(new Date(response.token.valid_until.toString()).getTime());
                    setIsCodeSent(true);

                    setLoading(false);
                } catch (err: any) {
                    const error: RequestError = err.response?.data;
                    if (error.error === "not_found") {
                        setError(activeTab, {
                            type: "404",
                            message: t("forgot_password_error_userNotFound"),
                        });
                    }

                    setLoading(false);
                }
            }
        },
        [activeTab, client, setError, setTempId, t],
    );

    const validateCode = useCallback(
        async (data: IForgotPassForm) => {
            setLoading(true);
            try {
                const response = await client.validateRecoveryToken({
                    code: data.code,
                    id,
                });

                setCode(data.code);
                setTempId(response.id);
                setStep(2);
            } catch (err: any) {
                const error: RequestError = err.response?.data;
                if (error.error === "recovery_error") {
                    setError("code", {
                        type: "400",
                        message: t("forgot_password_error_wrongCode"),
                    });
                }
                setLoading(false);
            }
        },
        [client, id, setCode, setError, setStep, setTempId, t],
    );

    const renderContent = useCallback(({ minutes, seconds, completed }: CountdownRenderProps) => {
        if (completed) {
            return null;
        }

        return (
            <span>
                <Translate
                    tid="forgot_password_new_code_resend"
                    replace={{
                        minutes,
                        seconds,
                    }}
                />
            </span>
        );
    }, []);

    const onCompleteHandler = useCallback(() => {
        setIsCodeSent(false);
        setIsResend(true);
        reset({ ...getValues(), code: undefined }, { keepDirty: true });
        setTempId("");
    }, [getValues, reset, setTempId]);

    const onSubmit = useCallback(
        (data: IForgotPassForm) => {
            const convertToRequestObj = (fields: IForgotPassForm): IInitiateRecoveryRequest => {
                const temp = { ...fields };
                return {
                    login: temp[activeTab] || "",
                };
            };

            if (isDirty && isValid && !isSubmitting && !loading) {
                let changedFields: IForgotPassForm = {} as IForgotPassForm;
                Object.keys(dirtyFields).forEach((field) => {
                    changedFields = {
                        ...changedFields,
                        [field]: data[field as keyof IForgotPassForm],
                    };
                });

                if (isCodeSent) {
                    validateCode(data);
                } else {
                    initiateRecovery(convertToRequestObj(changedFields));
                }
            }
        },
        [
            isDirty,
            isValid,
            isSubmitting,
            loading,
            activeTab,
            dirtyFields,
            isCodeSent,
            validateCode,
            initiateRecovery,
        ],
    );

    const changeHandler = useCallback(
        (checked: boolean) => {
            if (!loading) {
                if (checked) {
                    setActiveTab("phone");
                } else {
                    setActiveTab("email");
                }
            }
        },
        [loading],
    );

    useEffect(() => {
        setIsCodeSent(false);
        setIsResend(false);
        reset();
        setTempId("");
    }, [activeTab, reset, setTempId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {emailEnabled && phoneEnabled && (
                <div className={styles.forgotPassHeader}>
                    <div className="ui-switch-container">
                        <span
                            className={classnames(styles.iconGif, styles.email, {
                                [styles.active]: activeTab === "email",
                            })}>
                            <Img
                                source={`${ASSETS_URL}/${formatAppNameText(
                                    COMMON_SITE_CONFIGS.appName,
                                )}/desktop/login/login-mail${
                                    activeTab === "email" ? "-selected" : ""
                                }.gif`}
                                alt="email"
                                width={32}
                                height={32}
                            />
                        </span>
                        <ToggleSwitch
                            name="email-phone"
                            checked={activeTab === "phone"}
                            onChange={changeHandler}
                        />
                        <span
                            className={classnames(styles.iconGif, {
                                [styles.active]: activeTab === "phone",
                            })}>
                            <Img
                                source={`${ASSETS_URL}/${formatAppNameText(
                                    COMMON_SITE_CONFIGS.appName,
                                )}/desktop/login/login-mobile${
                                    activeTab === "phone" ? "-selected" : ""
                                }.gif`}
                                alt="email"
                                width={16}
                                height={22}
                            />
                        </span>
                    </div>
                </div>
            )}
            {activeTab === "email" && (
                <>
                    <p>
                        <Translate tid="forgot_password_email_txt" />
                    </p>
                    <Controller
                        render={({ field: { onChange, value, name } }) => {
                            return (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    label={t("forgot_password_email")}
                                    error={errors.email}
                                    disabled={isCodeSent}
                                />
                            );
                        }}
                        control={control}
                        name="email"
                    />
                </>
            )}
            {activeTab === "phone" && (
                <>
                    <p>
                        <Translate tid="forgot_password_sms_txt" />
                    </p>
                    <Controller
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                label="userData_mobile"
                                onChange={onChange}
                                disabled={isCodeSent}
                                value={value ?? ""}
                                separateDialCode
                                setError={setError}
                                clearErrors={clearErrors}
                                error={errors.phone || (errors.root?.apiError as FieldError)}
                            />
                        )}
                        control={control}
                        name="phone"
                    />
                </>
            )}

            <span>
                <Translate tid="forgot_password_enter_code" />
            </span>
            <div className={styles.forgotPassField}>
                <InputField
                    type="number"
                    name="code"
                    error={errors.code}
                    register={register}
                    placeholder="123456"
                    disabled={!isCodeSent}
                />
                {isCodeSent ? (
                    <Button
                        type="submit"
                        disabled={
                            !isDirty ||
                            !isValid ||
                            !!errors.root?.apiError ||
                            isSubmitting ||
                            loading
                        }
                        variant="secondary">
                        <Translate tid="forgot_password_next" />
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        disabled={
                            !isDirty ||
                            !isValid ||
                            !!errors.root?.apiError ||
                            isSubmitting ||
                            loading
                        }
                        variant="greyBtn">
                        {isResend ? (
                            <Translate tid="forgot_password_resend_code" />
                        ) : (
                            <Translate tid="forgot_password_request_code" />
                        )}
                    </Button>
                )}
            </div>
            {isCodeSent && !errors.root?.apiError && validUntil && (
                <Countdown
                    date={validUntil}
                    renderer={renderContent}
                    onComplete={onCompleteHandler}
                />
            )}
        </form>
    );
};

export default Step1;
