import { FC, useEffect, useState, useMemo, PropsWithChildren, useContext } from "react";
import { WebsocketClient } from "@finbackoffice/websocket-client";
import {
    AuthContext,
    WebsocketProvider as FeWebsocketProvider,
    LocaleContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { EventStatus, Language } from "@finbackoffice/enums";

type IWebsocketState = {
    connected: boolean;
};

export const WebsocketProvider: FC<PropsWithChildren> = ({ children }) => {
    const CLIENT_WS_ENDPOINT = useRuntimeConfig("CLIENT_WS_ENDPOINT");
    const { locale } = useContext(LocaleContext);
    const { userToken } = useContext(AuthContext);
    const socketClient = useMemo(
        () =>
            typeof window !== "undefined"
                ? new WebsocketClient(
                      userToken || "",
                      CLIENT_WS_ENDPOINT,
                      [EventStatus.Ended],
                      locale as Language,
                  )
                : null,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const [config, setConfig] = useState<IWebsocketState>({ connected: false });

    if (typeof window !== "undefined") {
        (window as any).socketClient = socketClient;
    }

    useEffect(() => {
        // We don't care about token here,
        // it could be explicitly send for specific methods like "wallet" or "ticket"

        if (socketClient) {
            socketClient.on("connected", () => {
                setConfig({
                    connected: true,
                });
            });

            socketClient.on("disconnected", () => {
                setConfig(() => ({
                    connected: false,
                }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FeWebsocketProvider
            socketClient={socketClient as WebsocketClient}
            token={userToken || ""}
            connected={config.connected}>
            {children}
        </FeWebsocketProvider>
    );
};
