import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InferType, number, object } from "yup";
import { ILoginResponse, ITokenResponse } from "@finbackoffice/clientbff-client";
import { DeliveryChannel } from "@finbackoffice/enums";
import Countdown from "react-countdown";
import { ClientBFFContext, useTranslation } from "@finbackoffice/site-core";
import { RequestError } from "@finbackoffice/fe-core";
import Button from "components/base/button/Button";
import InputField from "components/base/input-field/InputField";
import Translate from "components/base/translate/Translate";
import styles from "../form/login-form.module.sass";

const loginOtpValidationSchema = object({
    code: number().required(),
});

type ILoginOtpForm = InferType<typeof loginOtpValidationSchema>;

interface ILoginTwoFactorFormProps {
    token: ITokenResponse;
    performLogin: (data: ILoginResponse) => void;
    close: () => void;
}

const LoginTwoFactorForm: FC<ILoginTwoFactorFormProps> = ({ token, performLogin, close }) => {
    const client = useContext(ClientBFFContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const validUntil = new Date(token?.valid_until.toString()).getTime();

    const {
        register,
        handleSubmit,
        setError,
        formState: { isValid, isSubmitting, errors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(loginOtpValidationSchema),
    });

    const onSubmit = async (data: ILoginOtpForm) => {
        if (isValid && !isSubmitting) {
            setLoading(true);
            try {
                const response = await client.loginOtp({
                    code: data.code.toString(),
                    id: token.id,
                });

                if (response) {
                    setLoading(false);
                    performLogin(response);
                }
            } catch (err: any) {
                const error: RequestError = err.response?.data;

                if (error.error === "not_found") {
                    setError("code", {
                        type: "server",
                        message: t("login_2fa_error_wrongCode"),
                    });
                }

                setLoading(false);
            }
        }
    };

    const renderContent = ({ minutes, seconds, completed }: any) => {
        if (completed) {
            return null;
        }

        return (
            <p className={styles.resendTxt}>
                <Translate
                    tid="login_2fa_newCodeResend"
                    replace={{
                        minutes,
                        seconds,
                    }}
                />
            </p>
        );
    };

    const onCompleteHandler = () => {
        close();
    };

    return (
        <div className={styles.login2fa}>
            <p>
                <Translate
                    tid="login_2fa_codeIsSent"
                    replace={{
                        hint: token.hint,
                        channel:
                            token.channel === DeliveryChannel.Sms
                                ? t("login_2fa_channel_mobile")
                                : t("login_2fa_channel_email"),
                    }}
                />
            </p>
            <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
                <InputField
                    wrapperClassname={styles.twoFactorCodeField}
                    type="number"
                    register={register}
                    name="code"
                    label="login_2fa_code"
                    error={errors.code}
                />
                <Countdown
                    date={validUntil}
                    renderer={renderContent}
                    onComplete={onCompleteHandler}
                />
                <div className={styles.bottomContainer}>
                    <Button
                        type="submit"
                        className={styles.loginBtn}
                        variant="greenBtn"
                        disabled={!isValid || isSubmitting || loading}>
                        <Translate tid="header_login" />
                    </Button>
                </div>
            </form>
        </div>
    );
};
export default LoginTwoFactorForm;
