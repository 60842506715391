import {
    FC,
    createContext,
    useEffect,
    useMemo,
    PropsWithChildren,
    useContext,
    useState,
} from "react";
import {
    BrowserStorageContext,
    BrowserStorageIdsEnum,
    ConfigContext,
} from "@finbackoffice/site-core";
import { OddsFormat } from "@finbackoffice/clientbff-client";

interface IInitialDataContext {
    setSiteHeaderHeight: (val: number) => void;
    siteHeaderHeight: number;
}

export const InitialDataContext = createContext<IInitialDataContext>(null as any);

export const InitialDataProvider: FC<PropsWithChildren> = ({ children }) => {
    const [siteHeaderHeight, setSiteHeaderHeight] = useState(0);
    const { changeOddFormat, siteDefaultOddsFormat } = useContext(ConfigContext);
    const { get } = useContext(BrowserStorageContext);

    useEffect(() => {
        async function loadInitialData() {
            const storedOddFormat: OddsFormat =
                get(BrowserStorageIdsEnum.ODD_FORMAT) ?? siteDefaultOddsFormat;
            changeOddFormat(storedOddFormat);
        }

        loadInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = useMemo(
        () => ({
            setSiteHeaderHeight,
            siteHeaderHeight,
        }),
        [siteHeaderHeight],
    );

    return <InitialDataContext.Provider value={value}>{children}</InitialDataContext.Provider>;
};
