import classnames from "classnames";
import { FC, HTMLProps, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";
import { TranslationScopes } from "@finbackoffice/enums";
import Tooltip from "../tooltip/Tooltip";
import Translate, { ITranslateProps } from "../translate/Translate";
import styles from "./input-field.module.sass";

interface IInputFieldProps {
    label?: string | ITranslateProps;
    iconClass?: string;
    innerIconClass?: string;
    wrapperClassname?: string;
    register?: UseFormRegister<any>;
    error?: Partial<FieldError> | { type: string; message: string };
    tooltip?: {
        render: string;
        variant?: string;
        condition?: string;
    };
    name: string;
    onInnerIconClick?: (e: SyntheticEvent) => void;
    onIconClick?: (e: SyntheticEvent) => void;
    required?: boolean;
    enableAutoComplete?: boolean;
    separateDialCode?: boolean;
    updateForm?: () => void;
    showFocus?: boolean;
    type?: string;
    disabled?: boolean;
    registerOptions?: RegisterOptions;
    forwardRef?: (input: HTMLInputElement | null) => void;
}

const InputField: FC<IInputFieldProps & Omit<HTMLProps<HTMLInputElement>, "label">> = ({
    label,
    iconClass,
    innerIconClass,
    wrapperClassname,
    register,
    name,
    error,
    tooltip,
    onInnerIconClick,
    onIconClick,
    required,
    enableAutoComplete,
    separateDialCode,
    updateForm,
    showFocus,
    forwardRef,
    registerOptions,
    ...rest
}) => {
    const [autoComplete, setAutoComplete] = useState(enableAutoComplete ? "on" : "off");
    const inputRef = useRef<any>();

    useEffect(() => {
        if (!enableAutoComplete && navigator?.userAgent.includes("Chrome")) {
            setAutoComplete("new-password");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (separateDialCode && updateForm) {
            setTimeout(() => {
                updateForm();
            });
        }
    }, [separateDialCode, updateForm]);

    const handleRefSet = useCallback(
        (input: HTMLInputElement | null) => {
            inputRef.current = input;
            forwardRef?.(input);
        },
        [inputRef, forwardRef],
    );

    useEffect(() => {
        if (showFocus && inputRef?.current.getBoundingClientRect().y < window.innerHeight) {
            inputRef.current.focus();
        }
    }, [showFocus]);

    const input = register && register(name, registerOptions || {});

    return (
        <div className={wrapperClassname}>
            {iconClass && <i className={iconClass} onClick={onIconClick} />}
            {label && (
                <label htmlFor={name} className={styles.label}>
                    <Translate
                        tid={typeof label === "string" ? label : (label as ITranslateProps).tid}
                        namespace={
                            typeof label !== "string"
                                ? (label as ITranslateProps).namespace
                                : TranslationScopes.Common
                        }
                    />
                </label>
            )}
            <div
                className={classnames({
                    [styles.fieldError]: error,
                    required,
                })}>
                {register ? (
                    <input
                        id={name}
                        data-testid={`${name}-input`}
                        className={styles.inputStyle}
                        onChange={input?.onChange}
                        onBlur={input?.onBlur}
                        name={input?.name}
                        ref={(e) => {
                            input?.ref(e);
                            inputRef.current = e;
                        }}
                        autoComplete={autoComplete}
                        {...rest}
                    />
                ) : (
                    <input
                        id={name}
                        data-testid={`${name}-input`}
                        className={styles.inputStyle}
                        {...rest}
                        ref={handleRefSet}
                        autoComplete={autoComplete}
                    />
                )}
                {innerIconClass && <i className={innerIconClass} onClick={onInnerIconClick} />}
                {error && (
                    <span className={classnames(styles.error, "field-error")}>{error.message}</span>
                )}
                {tooltip && (
                    <Tooltip
                        targetRef={inputRef}
                        message={tooltip.render}
                        variant={tooltip.variant}
                        condition={tooltip.condition}
                    />
                )}
            </div>
        </div>
    );
};

export default InputField;
