import { useRuntimeConfig } from "@finbackoffice/site-core";
import { useRouter } from "next/router";

const isProduction = NODE_ENV === "production";

type IHost = {
    origin: string;
    originWithLocale: string;
    name: string;
    fullPath: string;
};

export const useHost = (): IHost => {
    const DOMAIN = useRuntimeConfig("DOMAIN");
    const protocol = isProduction ? "https" : "http";
    const { defaultLocale, asPath, locale } = useRouter();
    const hostWithProtocol = `${protocol}://${DOMAIN}`;

    const originWithLocale =
        defaultLocale === locale ? hostWithProtocol : `${hostWithProtocol}/${locale}`;

    return {
        origin: hostWithProtocol,
        originWithLocale,
        name: DOMAIN.split(".")[0] || DOMAIN,
        fullPath: `${originWithLocale}${asPath !== "/" ? asPath : ""}`,
    };
};
