import {
    MINUTE_IN_MS,
    HeaderLayoutEnum,
    SignupLayoutEnum,
    ISiteConfigLayouts,
} from "@finbackoffice/fe-core";
import {
    AuthContext,
    ConfigContext,
    CpfSignupContext,
    ModalsContext,
    PopupBannersContext,
    SiteNotificationsContext,
    UserAccountContext,
    useDocumentVisible,
    useRuntimeConfig,
    useSiteConfig,
} from "@finbackoffice/site-core";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import {
    FC,
    RefObject,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import AutoHeight from "components/base/auto-height/AutoHeight";
import Button from "components/base/button/Button";
import Loading from "components/base/loading/Loading";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import Translate from "components/base/translate/Translate";
import ForgotPass from "components/header/login-panel/forgot-pass/ForgotPass";
import { useLatestExchangeRates } from "hooks";
import { ModalTypes, RouterQuery } from "utils/constants";
import ErrorBoundary from "../base/error-boundary/ErrorBoundary";
import styles from "./header.module.sass";
import LoginForm from "./login-panel/form/LoginForm";
import { PopupBanners } from "./popup-banners/PopupBanners";
import SiteNotifications from "./site-notifications/SiteNotifications";

const V1 = dynamic(() => import("./layouts/v1/V1"), {
    ssr: true,
});

const V2 = dynamic(() => import("./layouts/v2/V2"), {
    ssr: true,
});

const Signup = dynamic(() => import("components/account/signup/Signup"), {
    loading: () => <Loading />,
    ssr: false,
});

type ILoginModalProps = {
    defaultView?: LoginViewType;
};

export type LoginViewType = "login" | "forgot";

export const LoginModal: FC<ILoginModalProps> = ({ defaultView }) => {
    const { loginModalRef } = useContext(ModalsContext);
    const [currentView, setCurrentView] = useState<LoginViewType>(defaultView || "login");

    const onModalClose = useCallback(() => {
        setCurrentView("login");
    }, []);

    useEffect(() => {
        if (defaultView) {
            setCurrentView(defaultView);
        }
    }, [defaultView]);

    return (
        <Modal
            ref={loginModalRef}
            styleClass={styles.loginModal}
            type={ModalTypes.LOGIN}
            maskClosable={false}
            onClose={onModalClose}>
            <AutoHeight duration={300}>
                {currentView === "login" ? (
                    <LoginForm setCurrentView={setCurrentView} />
                ) : (
                    <ForgotPass onComplete={() => setCurrentView("login")} />
                )}
            </AutoHeight>
        </Modal>
    );
};

export const SignupModal: FC = () => {
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const { siteRegistrationConfig } = useContext(ConfigContext);
    const { signupModalRef } = useContext(ModalsContext);
    const { step } = useContext(CpfSignupContext);
    const closable = useMemo(() => {
        if (siteLayoutsConfig.signup !== SignupLayoutEnum.CPFSignup) return true;
        return step !== "second" || !siteRegistrationConfig?.auto_id_verification;
    }, [siteLayoutsConfig.signup, step, siteRegistrationConfig]);

    return (
        <Modal
            ref={signupModalRef}
            styleClass={styles.signupModal}
            type={ModalTypes.SIGNUP}
            closable={closable}
            maskClosable={false}>
            <Signup />
        </Modal>
    );
};

type IHeaderContext = {
    isUserLoggedIn: boolean;
    loginDefaultView?: LoginViewType;
    showExchangeRatePanel: boolean;
};

export const HeaderContext = createContext<IHeaderContext>(null as any);

const Header: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const showExchangeRatePanel = useSiteConfig<boolean>("showExchangeRatePanel");
    const router = useRouter();
    const { hasPopups, popups } = useContext(PopupBannersContext);
    const { hasNotifications, notifications } = useContext(SiteNotificationsContext);
    const { logout } = useContext(UserAccountContext);
    const { loginModalRef, signupModalRef } = useContext(ModalsContext);
    const inactivityModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const { isUserLoggedIn } = useContext(AuthContext);
    const [loginDefaultView, setLoginDefaultView] = useState<LoginViewType | undefined>();
    const isTabActive = useDocumentVisible(
        COMMON_SITE_CONFIGS.detectInactivity.enable && isUserLoggedIn,
    );
    const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useLatestExchangeRates();

    useEffect(() => {
        if (router.query?.directory === "forgot") {
            setLoginDefaultView("forgot");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query?.directory]);

    useEffect(() => {
        if (router.query?.type === RouterQuery.Signup && signupModalRef.current) {
            signupModalRef.current.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query?.type]);

    useEffect(() => {
        if (router.query?.type === RouterQuery.Login && loginModalRef.current) {
            loginModalRef.current.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query?.type]);

    useEffect(() => {
        if (COMMON_SITE_CONFIGS.detectInactivity.enable) {
            if (!isTabActive) {
                inactivityTimeoutRef.current = setTimeout(async () => {
                    logout();

                    inactivityModalRef.current?.open();
                }, COMMON_SITE_CONFIGS.detectInactivity.period * MINUTE_IN_MS);
            } else {
                if (inactivityTimeoutRef.current) {
                    clearTimeout(inactivityTimeoutRef.current);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTabActive]);

    const renderLayout = useMemo(() => {
        switch (siteLayoutsConfig.header) {
            case HeaderLayoutEnum.V2:
                return <V2 />;
            default:
                return <V1 />;
        }
    }, [siteLayoutsConfig.header]);

    const closeInactivityModal = useCallback(() => {
        inactivityModalRef.current?.close();
    }, []);

    const openLoginModal = useCallback(() => {
        loginModalRef.current?.open();
    }, [loginModalRef]);

    const renderInactivityModal = useMemo(
        () => (
            <>
                <p>
                    <Translate
                        tid="inactivity_text"
                        replace={{ period: COMMON_SITE_CONFIGS.detectInactivity.period }}
                    />
                </p>
                <div>
                    <Button type="button" variant="greyBtn" onClick={closeInactivityModal}>
                        <Translate tid="base_ok" />
                    </Button>
                    <Button type="button" variant="secondary" onClick={openLoginModal}>
                        <Translate tid="header_login" />
                    </Button>
                </div>
            </>
        ),
        [COMMON_SITE_CONFIGS.detectInactivity.period, closeInactivityModal, openLoginModal],
    );

    const value = useMemo(
        () => ({
            isUserLoggedIn,
            loginDefaultView,
            showExchangeRatePanel,
        }),
        [isUserLoggedIn, loginDefaultView, showExchangeRatePanel],
    );

    return (
        <HeaderContext.Provider value={value}>
            {renderLayout}
            {hasNotifications && (
                <ErrorBoundary name={SiteNotifications.name}>
                    <SiteNotifications notifications={notifications} />
                </ErrorBoundary>
            )}

            {hasPopups && (
                <ErrorBoundary name={PopupBanners.name}>
                    <PopupBanners banners={popups} />
                </ErrorBoundary>
            )}
            {COMMON_SITE_CONFIGS.detectInactivity.enable && (
                <Modal
                    ref={inactivityModalRef}
                    styleClass={styles.inactivityModal}
                    type={ModalTypes.INACTIVITY}>
                    {renderInactivityModal}
                </Modal>
            )}
        </HeaderContext.Provider>
    );
};

export default Header;
