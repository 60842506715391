import { FC, useCallback, useContext, useEffect, useState } from "react";
import { ITokenResponse } from "@finbackoffice/clientbff-client";
import { RequestError, formatAppNameText } from "@finbackoffice/fe-core";
import classnames from "classnames";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    ClientBFFContext,
    getUserInfoValidationByActiveTab,
    loginEmailValidationRule,
    ModalsContext,
    PASSWORD_RULE,
    useRuntimeConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import { useRouter } from "next/router";
import { InferType } from "yup";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { differenceInYears } from "date-fns";
import { SignupInputFieldName } from "@finbackoffice/enums";
import { useDate, useSignupConfig } from "hooks";
import Translate, { ITranslateProps } from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import { LoginViewType } from "components/header/Header";
import ToggleSwitch from "components/base/toggle-switch/ToggleSwitch";
import Img from "components/base/img/Img";
import Loading from "components/base/loading/Loading";
import PhoneInput from "components/base/phone-input/PhoneInput";
import { Svg } from "components/base/svg/Svg";
import LoginTwoFactorForm from "../2fa/LoginTwoFactorForm";
import Input from "components/base/input-field/Input";
import styles from "./login-form.module.sass";

type ActiveTabType = "email" | "phone";

interface ILoginFormProps {
    setCurrentView: (val: LoginViewType) => void;
}

const LoginForm: FC<ILoginFormProps> = ({ setCurrentView }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const router = useRouter();
    const client = useContext(ClientBFFContext);
    const { clearCurrentModal, loginModalRef, signupModalRef } = useContext(ModalsContext);
    const { t } = useTranslation();
    const { formatDate } = useDate();
    const [activeTab, setActiveTab] = useState<ActiveTabType>(
        COMMON_SITE_CONFIGS.login.defaultActiveTab,
    );
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [twoFactorToken, setTwoFactorToken] = useState<ITokenResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState<ITranslateProps | null>();
    const [excludedErr, setExcludedErr] = useState<ITranslateProps | null>();
    const { signupConfigObj } = useSignupConfig([
        SignupInputFieldName.Password,
        activeTab as SignupInputFieldName,
    ]);

    const loginFormSchema = getUserInfoValidationByActiveTab(activeTab).shape({
        password: PASSWORD_RULE,
        email: loginEmailValidationRule,
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: { isValid, isSubmitting, errors },
    } = useForm({
        mode: "onChange",
        shouldUnregister: true,
        context: {
            activeTab,
            configObj: {
                ...signupConfigObj,
                t,
            },
        },
        resolver: yupResolver(loginFormSchema),
    });

    const performLogin = useCallback(async () => {
        clearCurrentModal();

        router.replace(router.asPath);
    }, [router, clearCurrentModal]);

    const onSubmit = useCallback(
        async (data: InferType<typeof loginFormSchema>) => {
            if (isValid && !isSubmitting) {
                setLoading(true);
                const req = {
                    login: data[activeTab],
                    password: data.password,
                };

                try {
                    const response = await client.login({
                        login: req.login as string,
                        password: req.password!,
                    });

                    if (response.token) {
                        setLoading(false);
                        if (typeof response.token === "string") {
                            performLogin();
                        } else if (response.token.id) {
                            setTwoFactorToken(response.token);
                        }
                    }
                } catch (err: any) {
                    const statusCode: number = err.response?.status;
                    const error: RequestError & { details: Record<string, string> } =
                        err.response?.data;

                    setLoading(false);
                    if (error.error === "self_excluded_login_error") {
                        differenceInYears(new Date(error.details.blocked_till), new Date()) > 1
                            ? setExcludedErr({ tid: "login_error_self_excluded_permanent" })
                            : setExcludedErr({
                                  tid: "login_error_self_excluded_temporary",
                                  replace: {
                                      blocked_till: formatDate(
                                          new Date(error.details.blocked_till),
                                          "dd MMM yyyy HH:mm:ss",
                                      ),
                                  },
                              });
                        return;
                    }
                    if (error.error === "blocked_error") {
                        setErr({ tid: "login_error_blocked" });
                        return;
                    }
                    if (statusCode === 400 || statusCode === 401 || statusCode === 403) {
                        setErr({ tid: "login_error_mismatch" });
                    }
                }
            }
        },
        [activeTab, client, isSubmitting, isValid, performLogin, formatDate],
    );

    const changeHandler = useCallback(
        (checked: boolean) => {
            if (!loading) {
                if (checked) {
                    setActiveTab("phone");
                } else {
                    setActiveTab("email");
                }
            }
        },
        [loading],
    );

    const onSignupPress = () => {
        signupModalRef.current?.open();
    };

    useEffect(() => {
        if (Object.keys(errors).length) {
            clearErrors();
        }
        setErr(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const renderLoginContent = () => {
        if (excludedErr) {
            return (
                <div className={styles.loginUserBlocked}>
                    <Translate tid={excludedErr.tid} replace={excludedErr.replace} dangerous>
                        <div />
                    </Translate>
                    <Button
                        type="button"
                        variant="secondary"
                        onClick={loginModalRef.current?.close}>
                        <Translate tid={"login_error_self_excluded_button_close"} />
                    </Button>
                </div>
            );
        }
        if (twoFactorToken) {
            return (
                <LoginTwoFactorForm
                    token={twoFactorToken}
                    performLogin={performLogin}
                    close={() => setTwoFactorToken(null)}
                />
            );
        }
        return (
            <form
                onChange={() => setErr(null)}
                onSubmit={handleSubmit(onSubmit)}
                data-testid="account-login-form">
                <div className={styles.loginHeader}>
                    {COMMON_SITE_CONFIGS.login.enableSwitch && (
                        <div className="ui-switch-container">
                            <span
                                className={classnames(styles.iconGif, {
                                    [styles.active]: activeTab === "email",
                                })}>
                                <Svg
                                    src={`/${formatAppNameText(
                                        COMMON_SITE_CONFIGS.appName,
                                    )}/desktop/login/login-username.svg`}
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </span>

                            <ToggleSwitch
                                name="email-phone"
                                testId="account-email-phone-toggle"
                                checked={activeTab === "phone"}
                                onChange={changeHandler}
                                disabled={err?.tid === "login_error_blocked"}
                            />
                            <span
                                className={classnames(styles.iconGif, {
                                    [styles.active]: activeTab === "phone",
                                })}>
                                <Img
                                    source={`${ASSETS_URL}/${formatAppNameText(
                                        COMMON_SITE_CONFIGS.appName,
                                    )}/desktop/login/login-mobile${
                                        activeTab === "phone" ? "-selected" : ""
                                    }.gif`}
                                    alt="email"
                                    width={16}
                                    height={22}
                                />
                            </span>
                        </div>
                    )}
                </div>
                {activeTab === "email" ? (
                    <Controller
                        render={({ field: { onChange, value, name } }) => {
                            return (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    label="login_email"
                                    error={errors.email}
                                    testId="account-email-input"
                                    enableAutoComplete
                                    disabled={err?.tid === "login_error_blocked"}
                                />
                            );
                        }}
                        control={control}
                        name="email"
                    />
                ) : (
                    <Controller
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                label="userData_mobile"
                                onChange={onChange}
                                testId="account-phone-input"
                                value={value ?? ""}
                                separateDialCode
                                setError={setError}
                                clearErrors={clearErrors}
                                error={errors.phone || (errors.root?.apiError as FieldError)}
                                disabled={err?.tid === "login_error_blocked"}
                            />
                        )}
                        control={control}
                        name="phone"
                    />
                )}
                <Controller
                    render={({ field: { onChange, value, name } }) => {
                        return (
                            <Input
                                onChange={onChange}
                                value={value}
                                name={name}
                                testId="account-password-input"
                                type={showPassword ? "text" : "password"}
                                onInnerIconClick={() => setShowPassword(!showPassword)}
                                label="userData_password"
                                placeholder={t("signup_form_password_placeholder", {
                                    replace: {
                                        min_length: signupConfigObj.password?.min_length,
                                        max_length: signupConfigObj.password?.max_length,
                                    },
                                })}
                                innerIconClass={classnames("viewPass", showPassword && "showPass")}
                                error={errors.password}
                                enableAutoComplete
                                disabled={err?.tid === "login_error_blocked"}
                            />
                        );
                    }}
                    control={control}
                    name="password"
                />

                <span className={styles.forgotPass} onClick={() => setCurrentView("forgot")}>
                    <Translate tid="login_forgotPass" />
                </span>
                {err && (
                    <span className={styles.loginError}>
                        <Translate tid={err.tid} replace={err.replace} />
                    </span>
                )}
                <div className={styles.bottomContainer}>
                    <Button
                        type="submit"
                        data-testid="account-submit-button"
                        className={styles.loginBtn}
                        disabled={
                            !isValid || isSubmitting || loading || !!errors.root?.apiError || !!err
                        }>
                        <Translate tid="header_login" />
                    </Button>
                    <Button type="button" variant="secondary" onClick={onSignupPress}>
                        <Translate tid="login_signup" />
                    </Button>
                </div>
            </form>
        );
    };

    return (
        <div className={styles.login}>
            <div>
                {COMMON_SITE_CONFIGS.skinVersion == SkinVersions.Betmidas ? (
                    <Img
                        source={`${ASSETS_URL}/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/desktop/logo.svg`}
                        alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                        title={`${COMMON_SITE_CONFIGS.appName} logo`}
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                    />
                ) : (
                    <Translate tid="login_head" />
                )}
            </div>
            {renderLoginContent()}
            {loading && <Loading />}
        </div>
    );
};
export default LoginForm;
