import classnames from "classnames";
import { FC, HTMLProps, MouseEventHandler } from "react";
import Loading from "components/base/loading/Loading";
import styles from "./button.module.sass";

interface IProps {
    type: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: any;
    variant?: string;
    icon?: string;
    loading?: boolean;
}

const Button: FC<IProps & HTMLProps<HTMLButtonElement>> = ({
    type,
    onClick,
    className,
    variant,
    icon,
    children,
    loading,
    ...rest
}) => (
    <button
        type={type === "button" ? "button" : "submit"}
        className={classnames(
            styles.button,
            variant && styles[variant],
            className,
            (rest.disabled || loading) && styles.disabled,
            icon && styles[icon],
        )}
        onClick={onClick}
        {...rest}>
        {loading ? <Loading /> : children}
    </button>
);

export default Button;
